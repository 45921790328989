<template>
	<div>
		<router-link v-if="getStatus != 8"  to="/miniapp">
			<a-button v-if="getStatus <= 0" @click="showAct" type="primary" ghost>请授权小程序</a-button>
			<a-button  v-if="getStatus > 0" type="primary" ghost>需小程序上线</a-button>
		</router-link>
		<a-button v-if="getStatus == 8" @click="showAct" type="primary" ghost>查看二维码</a-button>
		
		<a-modal title="二维码" :width="480" :visible="visible"  @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div class="flex alcenter center">
						<img  :src="url" style="width: 160px;"/>
					</div>
					<div class="mt16">
						<div class="ft12 ftwblod cl-info text-center">使用微信扫一扫</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props:{
			path:{
				type:String,
				default:'',
			},
			status:{
				type:Number,
				default:0,
			},
		},
		computed:{
			getStatus(){
				return this.status;
			}
		},
		data(){
			return{
				loading:false,
				visible:false,
				url:'',
			}
		},
		created(){
			
		},
		methods:{
			showAct(){
				this.visible = true;
				this.loading = true;
				let api = this.getStatus == 8 ?'platform/miniapp/getQrcode' : 'platform/miniapp/getTestQrcode' ;
				this.$http.papi(api,{
					path:this.path
				}).then(res=>{
					this.loading = false;
					this.url = res.img;
				}).catch(res=>{
					this.loading = false;
					console.log(res);
				});
			},
			handleCancel(){
				this.visible = false;
			}
			
		}
	}
</script>

<style>
</style>
