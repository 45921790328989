<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">买单设置</div>
			
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div class="flex">
						<div>
							<div class="">
								<div class="flex alcenter">
									<div class="ft14 ftw500 cl-info">是否开启买单</div>
									<div class="ml16">
										  <a-switch v-model="form.is_open==1" @change="changeOpen()"/>
									</div>
								</div>
							</div>
							
							<div class="mt24">
								<div class="flex alcenter">
									<div class="ft14 ftw500 cl-info">是否支持使用优惠券</div>
									<div class="ml16">
										<a-radio-group v-model="form.is_use_coupon">
											<a-radio :value="1">
												是
											</a-radio>
											<a-radio :value="0">
												否
											</a-radio>
										</a-radio-group>
									</div>
								</div>
							</div>
							
							<div class="mt24">
								<div class="flex alcenter">
									<div class="ft14 ftw500 cl-info">是否支持积分抵扣买单金额</div>
									<div class="ml16">
										<a-radio-group v-model="form.is_integral_deduction_balance">
											<a-radio :value="1">
												是
											</a-radio>
											<a-radio :value="0">
												否
											</a-radio>
										</a-radio-group>
									</div>
								</div>
							</div>
							
							<div class="mt24">
								<div class="flex alcenter">
									<div class="ft14 ftw500 cl-info">是否支持会员折扣</div>
									<div class="ml16">
										<a-radio-group v-model="form.is_vip_discount">
											<a-radio :value="1">
												是
											</a-radio>
											<a-radio :value="0">
												否
											</a-radio>
										</a-radio-group>
									</div>
								</div>
							</div>
							
							<div class="mt30">
								<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
							</div>
						</div>
					
						<div class="ml60" v-if="form.is_open">
							<div class="flex alcenter">
								<div class="ft14 ftw500 cl-info">买单收款码</div>
								<div class="ft12 ftw500 cl-notice">(可用于线下收款)</div>
							</div>
							<div class="mt12">
								<show-code :status="miniapp_status" path="/pages/client/buy/index"></show-code>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import showCode from '../../components/code/miniqrcode.vue';
	export default{
		components:{
			showCode,
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				form:{
					is_open:0,
					is_use_coupon:0,
					is_integral_deduction_balance:0,
					is_vip_discount:0,
				},
				miniapp_status:0,
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showBuySetting').then(res=>{
					this.form=res.detail;
					this.loading=false;
					this.miniapp_status = res.miniapp_status;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveBuySetting',{
					is_open:this.form.is_open,
					is_use_coupon:this.form.is_use_coupon,
					is_integral_deduction_balance:this.form.is_integral_deduction_balance,
					is_vip_discount:this.form.is_vip_discount,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			changeOpen(){
				this.form.is_open = this.form.is_open==1 ? 0 :1;
			}
		}
	}
</script>

<style>
	.setting-integral-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;
		
		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}
</style>
